import React, { useState, createContext } from "react"

export const FilterContext = createContext()

export function FilterProvider({ children }) {
	const [filters, setFilters] = useState("Chickidy check one on the mic")

	// eslint-disable-next-line react/jsx-no-constructed-context-values
	return <FilterContext.Provider value={{ filters, setFilters }}>{children}</FilterContext.Provider>
}
