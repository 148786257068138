import React from "react"
import { FilterProvider } from "./FilterProvider"

// https://youtu.be/52W__dKdNnU?t=464
// https://gist.github.com/stolinski/2d9545e19dd67bda64143cb1aae04ac0

function ProviderComposer({ contexts, children }) {
	return contexts.reduceRight(
		(kids, parent) =>
			React.cloneElement(parent, {
				children: kids,
			}),
		children,
	)
}

export function ContextProvider({ children }) {
	return <ProviderComposer contexts={[<FilterProvider />]}>{children}</ProviderComposer>
}
